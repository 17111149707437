<template>
  <div class="workList">
    <div class="likeTab">
      <div
        class="likeTabItem"
        :class="{ activeTab: kind == 0 }"
        @click="selectKind(0)"
      >
        动漫
      </div>
      <div
        class="likeTabItem"
        :class="{ activeTab: kind == 1 }"
        @click="selectKind(1)"
      >
        漫画
      </div>
      <div
        class="likeTabItem"
        :class="{ activeTab: kind == 3 }"
        @click="selectKind(3)"
      >
        真人
      </div>
      <div
        class="likeTabItem"
        :class="{ activeTab: kind == 8 }"
        @click="selectKind(8)"
      >
        剧场
      </div>
    </div>
    <PullRefresh
      :disabled="isRefreshDisable"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :error="error"
      :isNoData="isNoData"
      className="my-refresh"
    >
      <Waterfall :list="list" :key="kind" v-if="kind == 0" />
      <TransverselyTwoList
        :videoList="list"
        v-else-if="kind == 3"
      ></TransverselyTwoList>
      <VerticleThreeList :videoList="list" v-else> </VerticleThreeList>
    </PullRefresh>
  </div>
</template>

<script>
import PullRefresh from "@/components/PullRefresh";
import Waterfall from "./components/Waterfall.vue";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import PostItem from "@/components/PostItem";
import { queryWorkList, queryuserLikeList } from "@/api/mine";
export default {
  components: {
    // PostItem,
    Waterfall,
    PullRefresh,
    VerticleThreeList,
    TransverselyTwoList,
  },
  props: {
    isRefreshDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return "work";
      },
    },
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      kind: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411,
        kind: this.kind,
        // newsType: "COVER"
      };
      try {
        let res = await this.$Api(queryuserLikeList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.MediaList || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }

        // console.log(this.list);
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    selectKind(kind) {
      this.kind = kind;
      this.pageNumber = 1;
      this.list = [];
      // this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.isNoData = false;
      this.getList("refresh");
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.isNoData = false;
      this.getList("refresh");
    },
    // 关注-取消关注
    // async careOrcancle(item, index) {
    //     let req = {
    //         followUID: parseInt(item.uid),
    //         isFollow: !item.isFollow,
    //     }
    //     if (!item.uid) {
    //         Toast('当前用户信息错误');
    //         return;
    //     }
    //     this.$store.commit('app/SET_LOADING', true);
    //     try {
    //         let res = await this.$Api(careOrcancle, req);
    //         this.$store.commit('app/SET_LOADING', false);
    //         if (res.code === 200) {
    //             Toast(req.isFollow ? "关注成功" : "取消成功")
    //             item.isFollow = req.isFollow;

    //             return;
    //         }
    //         Toast(res.tip || `${req.isFollow ? '关注失败' : '取消失败'}`);
    //     } catch (error) {
    //         this.$store.commit('app/SET_LOADING', true);
    //         Toast('操作失败')
    //     }

    // },
  },
};
</script>

<style lang="scss" scoped>
.workList {
  // height: calc(100vh - 416px);
  .likeTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 53.5px;
    box-sizing: border-box;
    .likeTabItem {
      width: 52px;
      height: 26px;
      color: #999;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: #f5f5f5;
    }
    .activeTab {
      border: 2px solid rgba(0, 0, 0, 0.05);
      background: #fff;
    }
  }

  .list {
    padding: 0 12px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 9px;
    grid-column-gap: 9px;

    .workItem {
      width: 111px;
      height: 162px;
      position: relative;

      .mask {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        height: 24px;
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.9));

        .timer {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 8px;
          box-sizing: border-box;
          font-size: 13px;
        }
      }
    }
  }
  /deep/ .verticleTwoList,
  .verticleThreeList {
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>

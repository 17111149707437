<template>
  <div class="userHomepage">
    <div class="optBox oepnX" :class="{ isFixed: isFixed }">
      <div class="topOpt" v-if="!isFixed">
        <div @click="$router.go(-1)">
          <img class="backIcon" src="@/assets/png/leftH.png" />
        </div>
        <div
          class="follow"
          :class="{ followed: userInfo.isFollow }"
          v-if="userInfo.uid != currentUser.uid"
          @click="careOrcancle"
        ></div>
        <div class="changeBgBtn" @click="$router.push('editBackground')" v-else>
          更换背景
        </div>
      </div>
      <div @click="$router.go(-1)" v-else>
        <img class="backIcon" src="@/assets/png/leftH.png" />
      </div>
      <div class="uBox" v-show="isFixed">
        <ImgDecypt
          class="avatar"
          :src="userInfo.portrait"
          :key="userInfo.portrait"
          round
        />
        <div>{{ userInfo.name }}</div>
      </div>
      <div
        class="follow"
        v-show="isFixed"
        :class="{ followed: userInfo.isFollow }"
        v-if="userInfo.uid != currentUser.uid"
        @click="careOrcancle"
      ></div>
      <div
        v-show="isFixed"
        @click="$router.push('editBackground')"
        class="selectBg"
        v-else
      >
        <div>更换背景</div>
      </div>
    </div>
    <div class="contentBox">
      <div class="hearBox">
        <ImgDecypt class="bgImg" :src="backgroundImg" :key="backgroundImg" />
        <!-- <div
          class="follow"
          :class="{ followed: userInfo.isFollow }"
          v-if="userInfo.uid != currentUser.uid"
          @click="careOrcancle"
        >
          <svg-icon
            v-if="!userInfo.isFollow"
            icon-class="btnAddWhite"
          ></svg-icon>
          {{ userInfo.isFollow ? "已关注" : "关注" }}
        </div>
        <div class="avatarBox">
          <div class="avatarBorder">
            <ImgDecypt
              class="avatarImg"
              :src="userInfo.portrait"
              :key="userInfo.portrait"
              round
            />
          </div>
          <div class="nameAndLabel">
            <div class="userName">{{ userInfo.name }}</div>
          </div>
        </div> -->
      </div>
      <div class="main">
        <div class="userInfoBox">
          <div class="headerBox">
            <div class="avatarBorder">
              <ImgDecypt
                class="avatarImg"
                :src="userInfo.portrait"
                :key="userInfo.portrait"
                round
              />
            </div>
            <div class="dataBox">
              <router-link
                tag="div"
                :to="`/focusList?uid=${userInfo.uid}`"
                class="dataItem"
              >
                <div class="dataNum">{{ userInfo.follow | watchCount }}</div>
                <div class="dataText">关注</div>
              </router-link>
              <div class="verticalLine"></div>
              <router-link
                tag="div"
                :to="`/fanList?uid=${userInfo.uid}`"
                class="dataItem"
              >
                <div class="dataNum">{{ userInfo.fans | watchCount }}</div>
                <div class="dataText">粉丝</div>
              </router-link>
              <div class="verticalLine"></div>
              <div class="dataItem">
                <div class="dataNum">{{ userInfo.like | watchCount }}</div>
                <div class="dataText">点赞</div>
              </div>
            </div>
          </div>
          <div class="userName">{{ userInfo.name }}</div>
          <div class="userInfo">
            <div class="userId">妖精ID：{{ userInfo.uid }}</div>
            <div class="smallVerticalLine"></div>
            <div>{{ userInfo.age || 0 }}岁</div>
            <div class="smallVerticalLine" v-if="userInfo.region"></div>
            <div class="city">{{ userInfo.region }}</div>
            <div class="smallVerticalLine" v-if="userInfo.gender"></div>
            <svg-icon
              class="sexIcon"
              iconClass="maleIcon"
              v-if="userInfo.gender == 'male'"
            />
            <svg-icon
              class="sexIcon"
              iconClass="womanIcon"
              v-if="userInfo.gender == 'female'"
            />
          </div>
          <div class="summary">
            {{ userInfo.summary || "～有趣的介绍能让你的逼格提高N个档次！..." }}
          </div>
        </div>

        <!--                <div class="introduction">{{userInfo.summary || '才不是懒得写签名呢，只是这个人很神秘！'}}</div>-->
        <van-tabs
          v-model="activeTab"
          sticky
          animated
          background="#F5F5F5"
          title-active-color="#555"
          title-inactive-color="#999"
          offset-top="1.173rem"
          class="postTab"
          @scroll="scroll"
        >
          <van-tab title="动态">
            <PostList :isRefreshDisable="isRefreshDisable" type="work" />
          </van-tab>
          <van-tab title="喜欢">
            <LikeList :isRefreshDisable="isRefreshDisable" type="like" />
          </van-tab>
          <!--                    <van-tab title="帖子">-->
          <!--                        <PostList :isRefreshDisable="isRefreshDisable" />-->
          <!--                    </van-tab>-->
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { queryUserInfo } from "@/api/user";
import { PullRefresh, Toast, Tab, Tabs } from "vant";
import { careOrcancle } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
// import WorkList from "./WorkList";
import PostList from "./PostList";
import LikeList from "./LikeList";
export default {
  name: "UserHomePage",
  components: {
    ImgDecypt,
    // WorkList,
    PostList,
    LikeList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      currentUser: {}, // 当前用户
      userInfo: {}, // 用户信息
      backgroundImg: "", // 背景
      walletInfo: {}, // 钱包信息
      couponNum: 0, // 观阅券数量
      isLoading: false,
      activeTab: 0,
      isFixed: false,
      scrollTop: 0,
      isRefreshDisable: false,
    };
  },
  created() {
    this.currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    this.queryUserInfo();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    scrollToTop() {
      let dom = document.querySelector(".contentBox");
      this.scrollTop = dom.scrollTop;
      // console.log(this.scrollTop)
      if (this.scrollTop > 0) {
        this.isRefreshDisable = true;
      } else {
        this.isRefreshDisable = false;
      }
    },
    // 刷新
    onRefresh() {
      this.queryUserInfo();
    },
    // 查询用户信息
    async queryUserInfo() {
      let req = this.$route.query.uid
        ? {
            uid:
              this.$route.query.uid != this.$store.getters.userInfo.uid
                ? this.$route.query.uid
                : 0,
          }
        : {
            uid: 0,
          };
      let res = await this.$Api(queryUserInfo, req);
      this.isLoading = false;
      if (res.code === 200) {
        this.userInfo = res.data;
        this.backgroundImg = this.userInfo.background
          ? this.userInfo.background[0]
          : "";
      }
    },
    // 校验有效时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    scroll(scrollTop) {
      this.isFixed = scrollTop.isFixed;
    },
    // 关注-取消关注
    async careOrcancle() {
      // console.log(this.userInfo.uid)
      let req = {
        followUID: this.userInfo.uid,
        isFollow: !this.userInfo.isFollow,
      };
      if (!this.userInfo.uid) {
        Toast("当前用户信息错误");
        return;
      }
      let res = await this.$Api(careOrcancle, req);
      if (res.code === 200) {
        Toast(req.isFollow ? "关注成功" : "取消成功");
        this.userInfo.isFollow = req.isFollow;
        return;
      }
      Toast(res.tip || `${req.isFollow ? "关注失败" : "取消关注失败"}`);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
};
</script>

<style lang="scss" scoped>
.userHomepage {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .contentBox {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .optBox {
    width: 100%;
    position: fixed;
    // top: 12px;
    padding: 0 12px;
    z-index: 10;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .topOpt {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .backIcon {
      width: 24px;
      height: 24px;
    }
    .changeBgBtn {
      color: #fde39c;
      font-family: "PingFang SC";
      font-size: 12px;
      width: 72px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background: rgba(0, 0, 0, 0.6);
    }

    .uBox {
      flex: 2;
      display: flex;
      align-items: center;
      margin-left: 40px;

      .avatar {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .rightBox {
      display: flex;
      align-items: center;

      .privateLetter {
        width: 20px;
        height: 17px;
        // margin-right: 12px;
      }

      .setting {
        width: 18px;
        height: 21px;
      }
    }
  }

  .isFixed {
    background: #fff;

    .selectBg {
      color: #fde39c;
      font-family: "PingFang SC";
      font-size: 12px;
      width: 72px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .hearBox {
    width: 100%;
    height: 190px;
    position: relative;

    .backgroundMask {
      height: 150px;
      width: 100%;
      background-image: linear-gradient(
        180deg,
        #ffffff00 0%,
        #130f3270 50%,
        #130f32 100%
      );
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    .follow {
      font-size: 12px;
      width: 60px;
      height: 24px;
      border-radius: 25px;
      line-height: 25px;
      color: #ffffff;
      background: #94d6da;
      text-align: center;
      position: absolute;
      bottom: -12px;
      right: 16px;
      z-index: 3;
    }

    .followed {
      font-size: 12px;
      background: #b3b3b3;
    }

    .selectBg {
      font-size: 12px;
      font-weight: 500;
    }

    // .homePagebtn {
    //     position: absolute;
    //     right: 12px;
    //     z-index: 2;
    //     bottom: 0;
    //     width: 65px;
    //     height: 33px;
    //     background: url("../../assets/png/homePageBtn.png") no-repeat;
    //     background-size: 100% 100%;
    // }

    .avatarBox {
      position: absolute;
      bottom: -31px;
      left: 16px;
      z-index: 3;
      display: flex;
      align-items: flex-end;

      .avatarBorder {
        width: 60px;
        height: 60px;
        // padding: 5px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #1d164c;
        border: solid #fd2d55 1.88px;

        .avatarImg {
          width: 100%;
          height: 100%;
        }
      }

      .userName {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-left: 12px;
      }

      .editIcon {
        width: 24.3px;
        height: 24.3px;
      }
    }
  }

  .main {
    // overflow: hidden;
    height: calc(100% - 180px);
    //padding-top: 35px;
    padding-top: 170px;
    box-sizing: border-box;
    position: relative;
    .userInfoBox {
      position: absolute;
      top: -35px;
      left: 0;
      width: 100%;
      height: 205px;
      background: url("../../../assets/png/userInfoBg.png");
      background-size: 100% 100%;
      //   background-color: #fff;
      z-index: 2;
      .headerBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 9px 31px;
        box-sizing: border-box;
        .avatarBorder {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #31e0dc;
          border-radius: 50%;
          .avatarImg {
            width: 75px;
            height: 75px;
          }
        }
        .dataBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 202px;
          .dataItem {
            min-width: 32px;
            .dataNum {
              font-family: "zihunjinbangzhaopaiti";
              font-size: 20px;
              font-weight: 900;
            }
            .dataText {
              color: #b09fd9;
              font-family: "PingFang SC";
              font-size: 10px;
            }
          }
        }
      }
      .userName {
        margin-top: 6px;
        color: #333;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin: 0 43px;
      }
    }

    .smallVerticalLine {
      width: 1px;
      height: 10px;
      margin: 0 10px;
      background: #333333;
    }

    .nameAndLabel {
      padding: 0 12px;
      box-sizing: border-box;
      // margin-top: 24px;
      display: flex;
      align-items: center;

      .userName {
        font-size: 18px;
        margin-right: 12px;
      }

      .vipIcon {
        width: 24px;
        height: 12px;
        margin-right: 10px;
      }

      .userIcon {
        width: 42px;
        height: 15px;
        margin-right: 10px;
      }
    }

    .userInfo {
      display: flex;
      align-items: center;
      padding: 0 26px 0 43px;
      box-sizing: border-box;
      font-size: 12px;
      margin: 7px 0;
      color: #999999;

      .sexIcon {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        font-size: 200px;
      }
    }
    .summary {
      width: 306px;
      margin-left: 43px;
      box-sizing: border-box;
      //margin-bottom: 12px;
      font-family: "PingFang HK";
      font-size: 12px;
      font-weight: 600;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      //   background: #ffffff;
    }

    .userData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 12px 16px;
      box-sizing: border-box;
      //   background: #ffffff;

      .verticalLine {
        width: 1px;
        height: 20px;
        //margin: 0 12px;
        background: #333333;
      }

      .dataItem {
        display: flex;
        align-items: center;
        //min-width: 43px;

        .dataNum {
          font-size: 16px;
          color: #333333;
        }

        .dataText {
          font-size: 12px;
          margin-right: 8px;
          color: #333333;
        }
      }
    }

    .introduction {
      font-size: 15px;
      color: #827f9d;
      margin-top: 12px;
      padding: 0 12px;
      box-sizing: border-box;
    }

    .postTab {
      //margin-top: 11px;
      // height: calc(100% - 214px);
      background: #f5f5f5;
      /deep/ .nodata_box {
        height: calc(100vh - 448px);
      }
      /deep/.communityHead {
        margin-bottom: 18px;
        margin-top: 0;
      }

      /deep/ .van-sticky--fixed {
        top: 44px;
      }

      /deep/ .van-tabs__wrap {
        width: 100%;
        height: 73px;
        // padding: 0 16.5px;
        // box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 3.5px 4px;
        // box-sizing: border-box;
        // border-radius: 8px;
        // border: 1px solid rgba(255, 255, 255, 0.5);
        background: #f5f5f5;
        // width: 342px;
        // height: 33px;
        // margin: 20px auto 0;
        // width: 180px;
        // height: 36px;
      }

      /deep/ .van-tabs__content {
        height: 100%;
      }

      /deep/ .van-tab__pane {
        height: 100%;
      }

      /deep/ .van-tabs__line {
        display: none;
      }
      /deep/ .van-tab {
        width: 162px;
        height: 26px;
        border-radius: 8px;
      }

      /deep/ .van-tab__text {
        font-family: "Dream Han Sans TC";
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 18px;
      }

      /deep/ .van-tabs__nav {
        padding: 3.5px 4px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: #f0f0f3;
        width: 342px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding:  0 12px;
        // box-sizing: border-box;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
      }

      /deep/ .van-tab--active {
        border: 2.1px solid rgba(0, 0, 0, 0.05);
        background: #f0f0f3;
      }
    }

    .mb80 {
      margin-bottom: 150px;
    }
  }
}
.follow {
  width: 63px;
  height: 26px;
  background: url("../../../assets/png/followBtn.png");
  background-size: 100% 100%;
  // border-radius: 25px;
  // line-height: 25px;
  // color: #ffffff;
  // background: #94d6da;
  // text-align: center;
  // font-size: 12px;
}
.followed {
  font-size: 12px;
  background: url("../../../assets/png/followedBtn.png");
  background-size: 100% 100%;
}
</style>
